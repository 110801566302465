import { Controller } from "@hotwired/stimulus"
import AutoSubmit from '@stimulus-components/auto-submit'

// Connects to data-controller="search"
export default class extends Controller {
  connect() {
  }

  submit(event) {
    event.target.form.requestSubmit();
  }

}
